<template>
	
	<div class="mt-4 flex overflow-y-visible justify-between items-center">
		<button
			class="hidden lg:inline-block z-10"
		 @click="previousPage">
			<svg
				id="Capa_1"
				height="30px"
				width="30px"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				viewBox="0 0 185.343 185.343"
				xml:space="preserve"
				fill="white"
				stroke="#000000"
				transform="rotate(180)"
			>
				<g id="SVGRepo_bgCarrier" stroke-width="0" />
				<g
					id="SVGRepo_tracerCarrier"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<g id="SVGRepo_iconCarrier">
					<g>
						<g>
							<path
								style="fill: black"
								d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175 l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934 c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
							/>
						</g>
					</g>
				</g>
			</svg>
		</button>

		<transition-group
			ref="sliderContainer"
			class="flex lg:gap-10 overflow-x-scroll hide-scroll"
			tag="div"
			name="slide"
		>
			<IndexSlidersCategoriaItem
				v-for="cat in categorias"
				:key="cat.codigo"
				:categoria="cat"
			/>
			<IndexSlidersCategoriaItem
				key="asd3"
				:categoria="{
					codigo: categorias.length + 1,
					imagen: null,
					nombre: 'Ver más',
				}"
			/>
		</transition-group>

		<button class="hidden lg:inline-block z-10" @click="nextPage">
			<svg
				id="Capa_1"
				height="30px"
				width="30px"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				stroke="#000000"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				viewBox="0 0 185.343 185.343"
				xml:space="preserve"
			>
				<g>
					<g>
						<path
							style="fill: black"
							d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
						l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
						c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
						/>
					</g>
				</g>
			</svg>
		</button>
	</div>
</template>

<script lang="ts" setup>
import type { Categoria } from "../../../types/products.d.ts";

defineProps<{
	categorias: Categoria[];
}>();

const sliderContainer = ref(null);

const nextPage = () => {
	nextTick(() => {
		sliderContainer.value.$el.scrollBy({
			top: 0,
			left: 400,
			behavior: "smooth",
		});
	});
};

const previousPage = () => {
	nextTick(() => {
		sliderContainer.value.$el.scrollBy({
			top: 0,
			left: -400,
			behavior: "smooth",
		});
	});
};
</script>
